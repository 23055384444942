import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"   viewBox="0 0 816.000000 816.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,816.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M4050 5435 c-82 -19 -130 -38 -203 -82 -155 -94 -266 -274 -283 -458
l-7 -76 39 3 39 3 7 75 c21 230 205 424 438 462 41 7 78 17 82 22 3 5 5 21 4
35 -4 32 -26 35 -116 16z"/>
<path d="M4073 5294 c-141 -30 -260 -123 -322 -248 -31 -63 -51 -141 -51 -198
0 -28 2 -29 38 -26 l37 3 6 62 c15 162 147 301 318 336 62 13 69 18 65 50 -1
12 -3 25 -3 30 -1 9 -12 8 -88 -9z"/>
<path d="M4260 5211 c0 -30 0 -30 66 -33 l66 -3 -2 -219 c-1 -244 -4 -259 -66
-318 -60 -59 -83 -63 -324 -63 l-215 0 -5 80 c-5 77 -6 80 -30 80 l-25 0 0
-110 0 -110 260 0 260 0 51 27 c57 30 102 77 136 141 22 40 23 52 23 297 l0
255 -97 3 -98 3 0 -30z"/>
<path d="M4115 5150 c-132 -18 -250 -145 -263 -282 l-5 -48 36 0 c32 0 35 3
41 38 21 116 85 189 194 216 35 9 47 17 48 32 2 40 -9 50 -51 44z"/>
<path d="M4480 5050 c0 -28 2 -30 41 -30 l42 0 -5 -142 c-3 -113 -8 -150 -23
-178 -22 -43 -70 -89 -110 -106 -17 -7 -55 -29 -85 -50 l-55 -37 56 6 c116 11
205 73 251 172 21 45 23 68 26 223 l4 172 -71 0 -71 0 0 -30z"/>
<path d="M4035 4945 c-14 -13 -25 -36 -25 -50 0 -33 41 -75 73 -75 39 0 77 36
77 74 0 69 -76 101 -125 51z"/>
<path d="M2192 4197 c-21 -22 -22 -31 -22 -240 0 -204 1 -218 20 -237 25 -25
72 -26 93 -2 13 14 17 38 17 95 l0 77 104 0 c89 0 107 3 130 21 31 24 35 68 8
92 -15 14 -40 17 -130 17 l-112 0 0 35 0 35 108 0 c127 0 152 11 152 65 0 58
-22 65 -195 65 -145 0 -152 -1 -173 -23z"/>
<path d="M2644 4199 c-17 -19 -19 -41 -22 -213 -2 -105 -1 -205 3 -223 8 -42
29 -63 64 -63 47 0 61 27 61 113 l0 77 70 0 70 0 0 -78 c0 -64 4 -82 18 -95
25 -23 65 -21 88 4 17 19 19 39 19 239 0 281 16 260 -195 260 -147 0 -158 -1
-176 -21z m244 -141 l-3 -33 -65 0 -65 0 -3 33 -3 32 71 0 71 0 -3 -32z"/>
<path d="M3102 4204 c-21 -14 -22 -22 -22 -144 l0 -128 78 -113 c110 -161 126
-161 236 7 l76 116 0 113 c0 98 -3 116 -21 139 -23 29 -54 33 -87 10 -20 -14
-22 -23 -22 -120 0 -102 -1 -106 -34 -155 l-33 -50 -32 49 c-29 46 -31 54 -31
144 0 81 -3 99 -21 122 -23 29 -54 33 -87 10z"/>
<path d="M3550 4200 c-19 -19 -20 -33 -20 -238 0 -181 3 -221 16 -240 15 -21
21 -22 178 -22 145 0 165 2 179 18 15 16 17 48 17 240 0 284 16 262 -195 262
-142 0 -157 -2 -175 -20z m240 -240 l0 -130 -65 0 -65 0 0 130 0 130 65 0 65
0 0 -130z"/>
<path d="M4000 4200 c-19 -19 -20 -33 -20 -240 0 -282 -17 -260 198 -260 141
0 161 2 175 18 15 16 17 48 17 240 0 209 -1 223 -20 242 -26 26 -60 25 -88 0
-21 -20 -22 -29 -22 -195 l0 -175 -65 0 -65 0 0 175 c0 162 -2 177 -20 195
-11 11 -31 20 -45 20 -14 0 -34 -9 -45 -20z"/>
<path d="M4450 4193 c-20 -26 -21 -36 -18 -246 3 -203 4 -220 22 -233 27 -19
70 -18 89 4 13 14 17 38 17 95 0 68 2 77 19 77 11 0 35 -26 66 -72 26 -40 57
-83 68 -95 40 -44 107 -18 107 41 0 15 -13 47 -30 71 -16 24 -30 47 -30 52 0
5 14 14 30 20 l30 11 0 136 c0 174 10 166 -196 166 l-153 0 -21 -27z m240
-138 l0 -35 -65 0 -65 0 0 35 0 35 65 0 65 0 0 -35z"/>
<path d="M4912 4204 c-22 -15 -22 -19 -22 -243 0 -208 2 -229 18 -244 28 -26
67 -21 92 10 20 26 21 36 18 239 -3 192 -5 214 -22 233 -23 25 -53 27 -84 5z"/>
<path d="M5101 4194 c-12 -15 -21 -32 -21 -39 0 -7 9 -24 21 -39 17 -21 29
-26 65 -26 l44 0 0 -166 c0 -196 9 -223 71 -224 54 0 59 20 59 213 l0 177 48
0 c57 0 82 20 82 67 0 57 -19 63 -193 63 l-156 0 -20 -26z"/>
<path d="M5562 4204 c-22 -15 -22 -19 -22 -243 0 -208 2 -229 18 -244 16 -14
43 -17 173 -17 166 1 182 5 194 53 5 20 1 32 -19 51 -24 25 -31 26 -131 26
l-105 0 0 30 0 30 69 0 c81 0 121 23 121 69 0 47 -27 61 -113 61 l-77 0 0 35
0 35 109 0 c106 0 110 1 130 26 12 15 21 32 21 39 0 7 -9 24 -21 39 l-20 26
-153 0 c-121 0 -156 -3 -174 -16z"/>
<path d="M2392 3444 c-15 -11 -22 -25 -22 -50 0 -36 12 -45 73 -58 19 -5 27
-13 27 -28 0 -19 -4 -20 -50 -15 -55 7 -75 -7 -45 -30 32 -23 98 -17 125 12
35 37 19 74 -40 96 -58 22 -60 44 -3 44 50 0 65 12 39 31 -26 19 -76 18 -104
-2z"/>
<path d="M2660 3376 c0 -79 2 -86 26 -105 38 -30 98 -28 129 4 22 22 25 32 25
105 0 73 -2 80 -20 80 -18 0 -20 -7 -20 -66 0 -80 -11 -104 -48 -104 -43 0
-52 17 -52 97 0 66 -2 73 -20 73 -18 0 -20 -7 -20 -84z"/>
<path d="M2990 3355 l0 -105 71 0 c89 0 109 10 109 55 0 18 -4 36 -9 39 -5 3
-7 23 -4 45 7 53 -19 71 -104 71 l-63 0 0 -105z m127 49 c8 -21 -15 -35 -52
-32 -23 2 -31 9 -33 26 -3 20 1 22 38 22 28 0 43 -5 47 -16z m11 -96 c-3 -20
-10 -23 -50 -26 -41 -3 -48 -1 -48 16 0 27 8 32 57 32 40 0 44 -2 41 -22z"/>
<path d="M3342 3444 c-18 -12 -22 -24 -20 -52 3 -34 6 -37 51 -50 37 -10 47
-18 47 -34 0 -19 -4 -20 -50 -15 -55 7 -75 -7 -45 -30 32 -23 98 -17 125 12
35 37 19 74 -40 95 -59 20 -61 45 -3 45 50 0 65 12 39 31 -26 19 -76 18 -104
-2z"/>
<path d="M3664 3450 c-52 -21 -79 -93 -53 -143 26 -51 99 -74 150 -46 33 17 6
38 -40 32 -30 -4 -43 -1 -60 16 -27 27 -27 53 3 88 22 25 26 26 59 16 25 -8
38 -8 46 0 16 16 14 23 -14 35 -30 14 -61 14 -91 2z"/>
<path d="M3937 3363 c1 -54 2 -101 2 -105 1 -5 10 -8 21 -8 17 0 20 7 20 45 0
60 22 60 58 0 19 -32 34 -44 50 -45 13 0 22 3 20 8 -2 4 -12 24 -23 45 -18 36
-18 39 -2 48 11 7 17 22 17 44 0 47 -29 65 -105 65 l-61 0 3 -97z m123 38 c0
-21 -19 -31 -56 -31 -19 0 -24 5 -24 25 0 23 4 25 40 25 33 0 40 -4 40 -19z"/>
<path d="M4250 3355 c0 -98 1 -105 20 -105 19 0 20 7 20 105 0 98 -1 105 -20
105 -19 0 -20 -7 -20 -105z"/>
<path d="M4430 3355 c0 -104 0 -105 24 -105 19 0 23 5 23 28 0 61 0 62 46 62
70 0 102 55 57 100 -16 16 -33 20 -85 20 l-65 0 0 -105z m127 49 c7 -20 -13
-34 -48 -34 -26 0 -45 23 -33 41 9 16 74 10 81 -7z"/>
<path d="M4720 3440 c0 -17 7 -20 40 -20 l40 0 0 -85 c0 -78 2 -85 20 -85 18
0 20 7 20 85 l0 85 35 0 c28 0 35 4 35 20 0 19 -7 20 -95 20 -88 0 -95 -1 -95
-20z"/>
<path d="M5050 3355 c0 -98 1 -105 20 -105 19 0 20 7 20 105 0 98 -1 105 -20
105 -19 0 -20 -7 -20 -105z"/>
<path d="M5285 3451 c-66 -27 -82 -117 -31 -170 65 -68 176 -24 176 69 0 52
-13 76 -50 95 -32 16 -64 18 -95 6z m87 -48 c22 -20 23 -70 1 -95 -29 -32 -88
-21 -104 18 -9 26 4 73 24 86 20 13 60 9 79 -9z"/>
<path d="M5570 3355 c0 -98 1 -105 20 -105 18 0 20 7 20 60 0 33 2 60 4 60 3
0 30 -27 62 -60 31 -33 63 -60 71 -60 10 0 13 24 13 105 0 98 -1 105 -20 105
-17 0 -20 -7 -21 -52 -1 -29 -1 -56 0 -60 3 -19 -19 -4 -74 52 -33 33 -63 60
-67 60 -4 0 -8 -47 -8 -105z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
